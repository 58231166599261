@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/BillieDEMORegular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/BillieDEMO-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/BillieDEMO-Bold.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/BillieDEMO-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/BillieDEMO-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

.modal-content {
  /* background: #FFFFFF !important; */
  /* border-radius: 42px !important; */
  /* border: solid 3px transparent; */
  position: relative;
  z-index: 2;
}



.input:-webkit-autofill,
.input:-webkit-autofill:hover, 
.input:-webkit-autofill:focus{
  -webkit-text-fill-color: #333;
  transition: background-color 5000s ease-in-out 0s;
}

.none {
  display: none;
}

.place-holder-center::placeholder {
  text-align: center;
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.open-box {
  animation: zoom-in-zoom-out 1s ease infinite;
}

* {
  font-family: 'Billie DEMO', sans-serif !important;
}


.wrongNetwork {
  background-color: rgb(253, 64, 64);
  border: 1px solid rgb(253, 64, 64);
  color: rgb(255, 255, 255);
  padding: 0 20px;
  border-radius: 18px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}


.wrongNetwork img {
  width: 16px;
  margin-right: 8px;
}

.wrongNetwork:hover {
  background-color: rgb(252, 14, 14);
}


.input {
  border: solid 1px #ACACAC;
  height: 50px;
  border-radius: 16px;
  font-weight: bold;
  width: 100%;
  padding: 0 16px;
  outline: 0;
  background-color: #ffffff;
  color: #333333;
}

.input:focus {
  border-color: #FF8008;
}

.inputError {
  /* border-color:#FC8181; */
  box-shadow: none,
}

.errorWrapper {
  /* border: 1px solid #FC8181 !important; */
  border-radius: 20px;
  box-shadow: none,
}


.input::-webkit-input-placeholder { /* Edge */
  color: #AFAFAF;
}

.input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #AFAFAF;
}

.input::placeholder {
  color: #AFAFAF;
}

.h60 {
  height: 60px !important;
}


.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  position: relative;
  margin-bottom: 40px;
  // border-bottom: 1px solid #ebebeb;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    position: relative;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .btnClose {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 14px;
    top: 7px;
    z-index: 9;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    .closeIcon {
      width: 18px;
      height: 18px;
    }
  }
}

.content {
  padding: 20px;
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  @media (max-width: 767px) {
    max-height: calc(100vh - 260px);
  }
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(135, 141, 150);
    background-clip: padding-box;
    border: 3px solid transparent;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    background-clip: padding-box;
    border: 3px solid transparent;
    border-radius: 15px;
  }

  .subTitle {
    font-size: 24px;
    font-weight: bold;
    color: #008a81;
    margin-bottom: 24px;
  }
  .smallTitle {
    font-size: 16px;
    font-weight: bold;
    color: #878d96;
    margin-bottom: 16px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  // border-top: 1px solid #ebebeb;
}

.row {
  display: flex;
  .col {
    flex: 1;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}


.modal-md {
  max-width: 600px;
}

.containerLight {
  .head {
    .title {
      color: #333333;
    }
  }
  .content {
    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #dddddd;
    }
  }

}

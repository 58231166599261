.container {
  display: flex;
  align-items: center;
  outline: none;
  border: 1px solid;
  border-radius: 12px;
  width: 100% !important;
  border-color: #ACACAC;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 16px;
  cursor: pointer;
  .icon {
    width: 24px;
    margin-right: 16px;
  }
  .name {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }

  &:hover {
    border: 1px solid #FF8008 !important;
  }
}
